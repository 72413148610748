var $class="se2--header-se--promo-banner",$name="HeaderSE/promoBanner",$path="app/components/HeaderSE/partials/promoBanner/index.js",$this={$class,$name,$path,};import debounce from 'lodash/debounce';
// eslint-disable-next-line max-len
import redefineTargetAttributeOfSustainabilityLink from 'app/components/utilities/redefineTargetAttributeOfSustainabilityLink';

import shell from 'app/modules/shell';
import initializeSticky from 'app/modules/sticky/initializeSticky';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import { closeMobileMenuButtonSelector, openMobileMenuButtonSelector } from '../commonPart/config';

import { buttonSelector, closeButtonSelector, localStorageKey } from './config';

export default shell.registerPartial($this, ({
  addEventListener,
  configs: {
    css,
    messages,
  },
  mount,
  publishMessage,
}) => {
  mount((element) => {
    const currentDateString = new Date().toDateString();
    const savedDateString = localStorage.getItem(localStorageKey);

    if (savedDateString === currentDateString) {
      element.remove();
      return;
    }

    element.classList.remove(css.classNames.hide);

    const onCloseButtonClick = () => {
      localStorage.setItem(localStorageKey, currentDateString);
      publishMessage(messages.refreshTimelines);
      element.remove();
    };

    const onOpenMobileMenuButtonClick = () => element.classList.add(css.classNames.hide);

    const onCloseMobileMenuButtonClick = () => element.classList.remove(css.classNames.hide);

    const setListenersToMobileMenuButtons = () => {
      if (!isMobileBreakpoint()) return;
      addEventListener(document.querySelector(openMobileMenuButtonSelector), 'click', onOpenMobileMenuButtonClick);
      addEventListener(document.querySelector(closeMobileMenuButtonSelector), 'click', onCloseMobileMenuButtonClick);
    };

    addEventListener(element.querySelector(closeButtonSelector), 'click', onCloseButtonClick);
    addEventListener(element.querySelector(buttonSelector), 'click', onCloseButtonClick);

    redefineTargetAttributeOfSustainabilityLink(element.querySelector('a'));
    initializeSticky(element);

    setListenersToMobileMenuButtons();

    addEventListener(window, 'resize', debounce(setListenersToMobileMenuButtons, 300));
  });
});
