var $name="setCookie",$path="app/utilities/setCookie.js",$this={$name,$path,};import forEach from 'lodash/forEach';
import keys from 'lodash/keys';

/**
 * Appends "name=value;" string to document cookies.
 * @param { string } name
 * Cookie name.
 * @param { string } value
 * Cookie value.
 * @param { object } options
 * Options.
 * @param { string } options.path
 * Cookie path, defaults to the path portion document URL.
 * @param { string } options.domain
 * Cookie domain, defaults to the host portion of document URL.
 * @param { string } options.expires
 * Cookies expiration date as GMT string.
 * If not specified the cookies will expire at the end of the session.
 * @param { boolean } options.secure
 * Make the cookie secure (only transmittable over https).
 */
function setCookie(name, value = '', options = {}) {
  if (!name) {
    throw new Error('Cookie name is not specified');
  }

  const { expires } = options;

  const updatedOptions = {
    path: '/',
    ...options,
  };

  let dateOfExpire;

  // expires - number of seconds before cookie expiration
  // could be the Number, Date, 0 or not specified.
  // in case of number - expire will convert into Data object
  if (expires && typeof expires === 'number') {
    dateOfExpire = new Date();
    dateOfExpire.setTime(dateOfExpire.getTime() + expires * 1000);
  }

  if (dateOfExpire && dateOfExpire.toUTCString) {
    updatedOptions.expires = dateOfExpire.toUTCString();
  }

  let updatedCookie = `${name}=${encodeURIComponent(value)}`;

  // Find all exist optional fields
  const optionsKeys = keys(updatedOptions);

  // Update cookie with the optional fields value
  forEach(optionsKeys, (propName) => {
    updatedCookie += `; ${propName}`;
    const propValue = updatedOptions[propName];
    // We don't need to set cookies with "Secure" and "HttpOnly" options which have false or null value at all.
    // If we set its' name ("Secure" and "HttpOnly") - it already means true.
    // A secure cookie will not be sent to the server until a request is made using SSL and the HTTPS protocol.
    // Sites using HTTP can’t set cookies with the “secure” directive.
    // A cookie marked with "HttpOnly" will not be accessible through JavaScript and the document.cookie property.
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  });

  // Save combined cookie value
  document.cookie = updatedCookie;
}

export default setCookie;
