var $class="se2--utilities",$name="utilities",$path="app/components/utilities/isTransparentHeader.js",$this={$class,$name,$path,};import { css } from 'configs';
import { headerVersion8ClassName } from 'app/components/HeaderSE/config';

function isTransparentHeader() {
  const header = document.querySelector('header');
  if (header.classList.contains(headerVersion8ClassName)) return false;
  return header.classList.contains(css.classNames.transparent);
}

export default isTransparentHeader;
