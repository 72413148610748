var $class="se2--header-se--login-modal-for-mobile",$name="HeaderSE/loginModalForMobile",$path="app/components/HeaderSE/partials/loginModalForMobile/config.js",$this={$class,$name,$path,};export const modalClassName = $class;
export const closeButtonClassName = 'close-btn';
export const expandButtonClassName = 'expand-btn';
export const chartInfoBlockClassName = 'chart-subtitle-wrap';
export const modalElementClassName = 'modal-element';
export const modalWrapClassName = 'modal-wrap';
export const modalWrapOverlayClassName = 'wrap-modal-overlay';
export const placeholderClassName = 'modal-placeholder';
export const separatorClassName = 'separator';

export default {
  closeButtonClassName,
  expandButtonClassName,
  chartInfoBlockClassName,
  modalClassName,
  modalElementClassName,
  modalWrapClassName,
  modalWrapOverlayClassName,
  placeholderClassName,
  separatorClassName,
};
