var $class="se2--header-se--metabar",$name="HeaderSE/metabar",$path="app/components/HeaderSE/partials/metabar/config.js",$this={$class,$name,$path,};// eslint-disable-next-line import/prefer-default-export
export const metabarClassName = $class;
// need to LinkListAPCCountrySelector for HeaderAPC
export const paramName = 'ref_url';
export const regExpGetHref = /\/\w{2}\/\w{2}\/.+\?/gi;
export const selectorAPCCountrySelector = '.language-selector-toggler';
export const countAttribute = 'data-count';
export const dataAttributeTime = 'data-time';
export const priceDiffSelector = '.price-diff';
export const priceTextSelector = '.price-text';
export const priceInfoSelector = '.price-info';
export const stockPriceSelector = '.header-stock-price';
export const stringValueFormaxNumberInCounter = '99+';
