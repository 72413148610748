var $class="se2--header-se--country-selector-banner",$name="HeaderSE/countrySelectorBanner",$path="app/components/HeaderSE/partials/countrySelectorBanner/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import { messages } from 'configs/index';

export default shell.registerPartial($this, ({
  addEventListener, mount, publishMessage,
}) => {
  mount((element) => {
    addEventListener(element, 'click', () => {
      publishMessage(messages.openCountrySelectorModal);
      return false;
    });
  });
});
