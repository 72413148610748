var $class="se2--header-se--login-for-mobile",$name="HeaderSE/loginForMobile",$path="app/components/HeaderSE/partials/loginForMobile/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import once from 'lodash/once';

import { chartInfoBlockClassName } from '../loginModalForMobile/config';

const loginAscoTooltipforMobile = (element) => {
  const popupModalTogglerElement = element.querySelector('.pop-up-login');
  const infoDescription = element.querySelector('.description-login-popup');
  const closeButtonClassName = 'close-btn';

  const importModal = once(
    async () => (await import('/app/components/HeaderSE/partials/loginModalForMobile/')).default,
  );

  const closeModal = async () => {
    const modal = await importModal();
    if (modal.wrapElement.classList?.contains(chartInfoBlockClassName)) {
      modal.hideModal();
    }
  };

  const openTooltipModal = async () => {
    let modalElement = null;
    const modal = await importModal();
    modalElement = modal.showModal({
      isCharts: true,
      appendHTML: `${infoDescription?.cloneNode(true).innerHTML}`,
    });
    modalElement.querySelector(`.${closeButtonClassName}`)?.addEventListener('click', () => {
      closeModal();
    });
  };

  popupModalTogglerElement?.addEventListener('click', () => {
    openTooltipModal();
  });
};

export default shell.registerComponent($this, ({ mount }) => {
  mount((element) => {
    loginAscoTooltipforMobile(element);
  });
});
