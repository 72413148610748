var $name="deleteCookie",$path="app/utilities/deleteCookie.js",$this={$name,$path,};import setCookie from './setCookie';

/**
 * Removes cookie by its name.
 * @param { string } name
 */
function deleteCookie(name) {
  setCookie(name, '', {
    expires: 0,
  });
}

export default deleteCookie;
