var $name="sso/index",$path="app/modules/sso/index.js",$this={$name,$path,};import ajax from 'app/modules/ajax';
import shell from 'app/modules/shell';

import deleteCookie from 'app/utilities/deleteCookie';
import getCookie from 'app/utilities/getCookie';
import setCookie from 'app/utilities/setCookie';

import { sso, messages } from 'configs';

import some from 'lodash/some';

export default shell.registerModule($this, ({ log, publishMessage, settings, subscribeToMessage }) => {
  let oidcManager, oidcSettings;
  let wasInitialized = false;

  const { sdlSilentSSODisabled } = window;
  const { countryCode, languageCode } = settings.page;
  const { origin } = window.location;
  const configEndpointURL = `/${countryCode}/${languageCode}/${sso.configEndpointURL}`;
  const keysEndpointURL = `${origin}/${countryCode}/${languageCode}/${sso.keysEndpointURL}`;
  const redirectEndpointURL = `${origin}${sso.redirectEndpointURL}`;

  function clearUserData() {
    deleteCookie(sso.idTokenCookieName);
    localStorage.removeItem(sso.userDataLocalStorageName);
  }

  function logout() {
    log.info('Logout process started');
    clearUserData();
    setCookie(sso.userTypeCookieName, sso.nonAuthorizedUserType);
    window.location.href = `${oidcSettings.post_logout_redirect_uri}?RelayState=${window.location.href}`;
  }

  function updateUserType() {
    !getCookie(sso.userTypeCookieName) && setCookie(sso.userTypeCookieName, sso.nonAuthorizedUserType, {
      expires: sso.cookieExpire,
    });
  }

  function updateUserFullName(userName, userSurname) {
    publishMessage(
      messages.ssoUserNameUpdated,
      { name: userName, surname: userSurname },
    );
  }

  function startSSO(isSessionExists) {
    clearUserData();

    log.info('IDMS request for user session engaged');

    return oidcManager?.signinSilent()
      .then((user) => {
        if (!isSessionExists) {
          return Promise.resolve(user.business_type);
        }
        // eslint-disable-next-line no-underscore-dangle
        const { firstName, lastName } = oidcManager._jwtParser.parseJwt(user.id_token).payload.user;
        updateUserFullName(firstName, lastName);
        return null;
      })
      .catch((err) => Promise.reject(err));
  }

  function isReferrerAllowed() {
    if (!window.ssoAllowedReferrersList) {
      log.warn('Allowed referrers list not found. Aborting');
      return false;
    }

    return some(window.ssoAllowedReferrersList, (allowedReferrer) => document.referrer.includes(allowedReferrer));
  }

  function silentSignIn() {
    log.info('User local session lookup engaged');
    oidcManager?.getUser()
      .then((user) => {
        if (user) {
          log.info('User local session found');
          // Get user data
          // eslint-disable-next-line no-underscore-dangle
          const { payload } = oidcManager._jwtParser
            .parseJwt(user.id_token);

          updateUserFullName(payload.user.firstName, payload.user.lastName);

          // Update user signin session if needed
          payload.cexp < Date.now() && startSSO(true);
        } else {
          log.info('User local session not found');
          // Start silent SSO signin, if user came from allowed referrer.
          if (isReferrerAllowed()) {
            startSSO(false)
              .then((userBusinessType) => {
                log.info('User business type updated, page reload engaged');
                const userType = userBusinessType || sso.nonAuthorizedUserType;
                setCookie(sso.userTypeCookieName, userType, {
                  expires: sso.cookieExpire,
                });
                window.location.reload();
              }, (err) => log.error('Unable to sign in:', err));
          } else log.warn('Referrer "%s" is not allowed', document.referrer);
        }
      });
  }

  function getOIDCSettings(data) {
    const oidcUrl = new URL(data.configs.login_endpoint);
    return {
      ...sso.oidcSettings,
      authority: data.configs.login_endpoint,
      client_id: data.configs.client_id,
      jwks_uri: keysEndpointURL,
      metadataUrl: `${oidcUrl.origin}${sso.oidcSettings.metadataUrl}`,
      post_logout_redirect_uri: data.configs.logout_endpoint,
      silent_redirect_uri: redirectEndpointURL,
    };
  }

  function initializeSSO() {
    if (wasInitialized) return;

    wasInitialized = true;

    log.info('Engage SSO configuration request');
    ajax
      .fetchJSON(configEndpointURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      })
      .then(
        (data) => {
          oidcSettings = getOIDCSettings(data);
          return import('./oidcClient').then(
            ({ default: { UserManager } }) => {
              oidcManager = new UserManager(oidcSettings);
            },
          );
        },
      )
      .then(
        () => {
          updateUserType();
          silentSignIn();
        },
        (error) => {
          log.error('Failed to inizialize:', error);
        },
      );
  }

  if (!sdlSilentSSODisabled) {
    subscribeToMessage(messages.ssoLogout, logout);

    if (getCookie(sso.idTokenCookieName) !== null || isReferrerAllowed()) initializeSSO();
  }

  return initializeSSO;
});
