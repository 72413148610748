var $class="se2--header-se--login-banner",$name="HeaderSE/loginBanner",$path="app/components/HeaderSE/partials/loginBanner/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import onSignInMenuKeyDown from 'app/components/utilities/onSignInMenuKeyDown';
import {
  popupWidth,
  signInButtonSelector,
  signInMenuSelector,
  expandedClassName,
  menuLinkSelector,
  lastMenuLinkSelector,
} from './config';

export default shell.registerComponent($this, ({ addEventListener, configs, mount }) => {
  mount((element) => {
    const signInButtonElement = document.querySelector(signInButtonSelector);
    const signInMenuElement = element.querySelector(signInMenuSelector);
    const menuLinkElement = document.querySelector(menuLinkSelector);
    const lastMenuLinkElement = document.querySelector(lastMenuLinkSelector);
    const direction = document.documentElement.getAttribute('dir');
    const buttonRect = signInButtonElement?.getBoundingClientRect();
    if (buttonRect) {
      if (direction === 'ltr') {
        signInMenuElement.style.right = `${buttonRect.right - popupWidth}px`;
      } else {
        signInMenuElement.style.left = `${buttonRect.left}px`;
      }
    }
    const createOnSignInMenuKeyDown = () => (event) => onSignInMenuKeyDown(
      event,
      element,
      signInButtonElement,
      expandedClassName,
      menuLinkElement,
      lastMenuLinkElement,
    );

    addEventListener(signInButtonElement, 'click', () => {
      if (buttonRect) {
        if (direction === 'ltr') {
          signInMenuElement.style.left = `${buttonRect.right - popupWidth}px`;
        } else {
          signInMenuElement.style.left = `${buttonRect.left}px`;
        }
      }
      element.classList.toggle(configs.css.classNames.open);
      signInButtonElement?.classList.toggle(expandedClassName);
      menuLinkElement?.focus();
    });

    addEventListener(
      signInMenuElement,
      'keydown',
      createOnSignInMenuKeyDown(),
    );

    addEventListener(window, 'click', ({ target }) => {
      if (target !== signInButtonElement && target.parentNode !== signInButtonElement) {
        element?.classList.remove(configs.css.classNames.open);
        signInButtonElement?.classList.remove(expandedClassName);
      }
    }, true);

    addEventListener(window, 'scroll', () => {
      element?.classList.remove(configs.css.classNames.open);
      signInButtonElement?.classList.remove(expandedClassName);
    });

    addEventListener(window, 'resize', () => {
      element?.classList.remove(configs.css.classNames.open);
      signInButtonElement?.classList.remove(expandedClassName);
    });
  });
});
