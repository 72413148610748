var $class="se2--header-se--brand-selector",$name="HeaderSE/brandSelector",$path="app/components/HeaderSE/deferrals/brandSelector/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import ajax from 'app/modules/ajax';
import toggleBrandListVisibility from 'app/components/HeaderSE/deferrals/brandSelector/utilities/brandSelectorShared';
import { desktopBrandSelectorClassName, mobileBrandSelectorClassName } from './config';

export default shell.registerPartial($this, ({
  addEventListener, mount, subscribeToMessage,
}) => {
  mount((element) => {
    const loaderClassName = 'se2-icon-loader';
    const nestedCountrySelectorSelector = '.stubbed-content';

    const desktopBrandSelectorElement = document.querySelector(`.${desktopBrandSelectorClassName}`);
    const mobileBrandSelectorElement = document.querySelector(`.${mobileBrandSelectorClassName}`);
    let wasInitialized = false;

    const showBrandSelectorOnClick = (brandSelectorElement) => {
      const placeholderElement = brandSelectorElement.querySelector(nestedCountrySelectorSelector);
      if (placeholderElement) {
        const { path } = brandSelectorElement.querySelector(nestedCountrySelectorSelector).dataset;

        return ajax.fetchDeferralTo(path, placeholderElement)
          .then(() => placeholderElement.classList.remove(loaderClassName));
      }
      return Promise.reject();
    };

    const onBrandSelectorClick = ({ currentTarget }) => {
      toggleBrandListVisibility(element);
      showBrandSelectorOnClick(element)
        .then(() => {
          import('./utilities/initializeBrandSelector').then(
            ({ default: initializeBrandSelector }) => {
              currentTarget.removeEventListener('click', onBrandSelectorClick);

              if (!wasInitialized) {
                initializeBrandSelector(element, addEventListener, subscribeToMessage);
                wasInitialized = true;
              }
            },
          );
        },
        () => {});
    };

    addEventListener(desktopBrandSelectorElement, 'click', onBrandSelectorClick);
    addEventListener(mobileBrandSelectorElement, 'click', onBrandSelectorClick);
  });
});
