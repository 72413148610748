var $class="se2--header-se--geolocation-banner",$name="HeaderSE/geolocationBanner",$path="app/components/HeaderSE/partials/geolocationBanner/index.js",$this={$class,$name,$path,};import ajax from 'app/modules/ajax';
import initializeSticky from 'app/modules/sticky/initializeSticky';
import setStickyAnimation from 'app/modules/sticky/setStickyAnimation';
import shell from 'app/modules/shell';

import getCookie from 'app/utilities/getCookie';
import setCookie from 'app/utilities/setCookie';

import some from 'lodash/some';

import {
  acceptButtonSelector,
  closeBannerButtonSelector,
  openCountrySelectorButtonSelector,
} from './config';

export function setCookies(keyword, configs, countryCode) {
  if (keyword === configs.geolocation.setCookieKeywords.locale && countryCode) {
    setCookie(
      configs.geolocation.cookies.locale,
      countryCode,
      {
        expires: configs.geolocation.baseCookieExpire,
        domain: window.location.hostname,
      },
    );
  }

  if (keyword === configs.geolocation.setCookieKeywords.locale
    || keyword === configs.geolocation.setCookieKeywords.geolocated) {
    setCookie(
      configs.geolocation.cookies.geolocated,
      true,
      configs.geolocation.baseCookieExpire,
    );
  }

  if (keyword === configs.geolocation.setCookieKeywords.countrySelect) {
    setCookie(
      configs.geolocation.cookies.countrySelected,
      true,
      configs.geolocation.baseCookieExpire,
    );
  }
}

export default shell.registerPartial($this, ({
  addEventListener,
  configs,
  log,
  mount,
  settings,
  subscribeToMessage,
  publishMessage,
  unmount,
}) => {
  const isGoogleBotUserAgent = settings.platform.system === configs.systems.googlebot;
  const accessories = new Map();

  let localeCookie = null;
  let geolocatedCookie = null;
  let countrySelectedCookie = null;

  function getCookies() {
    const {
      locale,
      geolocated,
      countrySelected,
    } = configs.geolocation.cookies;

    localeCookie = getCookie(locale);
    geolocatedCookie = getCookie(geolocated);
    countrySelectedCookie = getCookie(countrySelected);
    log.info('geolocation cookies: %o',
      {
        localeCookie,
        geolocatedCookie,
        countrySelectedCookie,
      });
  }

  function openBanner(bannerElement) {
    if (!countrySelectedCookie) {
      bannerElement.classList.remove(configs.css.classNames.hide);
    }
  }

  function closeBanner(bannerElement) {
    bannerElement.classList.add(configs.css.classNames.hide);
  }

  function acceptUserLocation(bannerElement) {
    setCookies(configs.geolocation.setCookieKeywords.countrySelect, configs);
    closeBanner(bannerElement);
  }

  function openCountrySelector() {
    publishMessage(configs.messages.openCountrySelector);
  }

  function redirect(countryCode, resolveCallback, rejectCallback) {
    log.info('Engaging redirect to country:', countryCode);
    ajax.fetchJSON(`${configs.geolocation.getLocaleByCountryCodeEndpoint}/${countryCode}`).then(
      (json) => {
        resolveCallback?.();
        const { origin } = window.location;
        window.location.href = `${origin}${json.Url.toLowerCase()}`;
      },
      () => {
        log.error('Unable to redirect');
        rejectCallback?.();
      },
    );
  }

  function polling(retryLimit, onFail) {
    const demandBaseData = window.stat_data && JSON.parse(
      window.stat_data.get(configs.geolocation.demandBaseAPIDataKey),
    );

    if (demandBaseData) {
      const userCountryCode = demandBaseData.country?.toLowerCase()
        || demandBaseData.registry_country_code?.toLowerCase();

      log.info('Polling success. Proposed country = %s', userCountryCode);

      redirect(userCountryCode,
        () => {
          setCookies(configs.geolocation.setCookieKeywords.locale, configs, userCountryCode);
        },
        onFail);
    } else if (retryLimit <= 0) {
      log.info('Polling retry limit depleted');
      onFail();
    } else {
      // eslint-disable-next-line no-plusplus
      log.info('%i retries remaining', retryLimit - 1);
      setTimeout(polling.bind(null, retryLimit - 1, onFail), configs.geolocation.geolocationAPIRetryInterval);
    }
  }

  function geolocate(bannerElement) {
    log.info(
      'Engage geolocation API. Polling interval = %i, retry limit = %i',
      configs.geolocation.geolocationAPIRetryInterval,
      configs.geolocation.geolocationAPIRetryLimit,
    );

    polling(configs.geolocation.geolocationAPIRetryLimit, () => openBanner(bannerElement));
  }

  function run(bannerElement) {
    if (!configs.geolocation?.geolocationEnabled || isGoogleBotUserAgent) {
      log.info('System has been disabled');
      return;
    }

    const { countryCode, languageCode } = settings.page;
    const isCurrentLocaleGlobal = countryCode === configs.countryCodes.default;
    const isCurrentLocaleSameAsCookie = countryCode === localeCookie || countryCode + languageCode === localeCookie;
    const isGlobalLocalePreferred = some(
      configs.geolocation.globalLocaleCookiesList,
      (cookie) => cookie === localeCookie,
    );

    log.info('Deciding on geolocation: %o', {
      isCurrentLocaleGlobal,
      isCurrentLocaleSameAsCookie,
      isGlobalLocalePreferred,
      isHomePage: settings.page.isHomePage,
    });

    if (!geolocatedCookie) {
      log.info('User hasn\'t been geolocated previously');
      if (!isCurrentLocaleGlobal || !settings.page.isHomePage) return;

      if (localeCookie) {
        if (isGlobalLocalePreferred) return;

        redirect(localeCookie, () => {
          setCookies(configs.geolocation.setCookieKeywords.geolocated, configs);
        });
      } else {
        geolocate(bannerElement);
      }
    } else if (!countrySelectedCookie) {
      log.info('User have been geolocated, proposed locale not yet set as preferred');
      if (!localeCookie || !isCurrentLocaleSameAsCookie || !settings.page.isHomePage) return;

      openBanner(bannerElement);
    } else if (isCurrentLocaleGlobal && settings.page.isHomePage && !isGlobalLocalePreferred) {
      log.info('Geolocation is done, redirect user to preferred locale');
      redirect(localeCookie);
    }
  }

  function setListeners(bannerElement) {
    addEventListener(
      bannerElement.querySelector(acceptButtonSelector),
      'click',
      accessories.get(bannerElement).acceptUserLocation,
    );
    addEventListener(
      bannerElement.querySelector(openCountrySelectorButtonSelector),
      'click',
      openCountrySelector,
    );
    addEventListener(
      bannerElement.querySelector(closeBannerButtonSelector),
      'click',
      accessories.get(bannerElement).closeBanner,
    );
  }

  mount((element) => {
    initializeSticky(element);
    setStickyAnimation(element, element.previousElementSibling, subscribeToMessage);
    accessories.set(element, {
      closeBanner: () => closeBanner(element),
      acceptUserLocation: () => acceptUserLocation(element),
    });

    getCookies();
    run(element);
    setListeners(element);
  });

  unmount((element) => {
    accessories.delete(element);
  });
});
