var $class="se2--header-se--brand-selector",$name="HeaderSE/brandSelector",$path="app/components/HeaderSE/deferrals/brandSelector/config.js",$this={$class,$name,$path,};export const backButtonClassName = 'back-btn';
export const brandLinkSelector = '.brand-list li a';
export const brandListClassName = 'brand-list';
export const brandSelectorClassName = $class;
export const desktopBrandSelectorClassName = 'brand-selector-toggler';
export const expandedClassName = 'is-expanded';
export const firstBrandLinkSelector = '.brand-list li:first-child a';
export const itemClassName = 'item';
export const lastBrandLinkSelector = '.brand-list li:last-child a';
export const listHeadClassName = 'list-head';
export const mobileBrandSelectorClassName = 'mobile-brand-toggler';
export const mobileCloseButtonClassName = 'list-close-btn';
export const mobileNavigationCloseButtonClassName = 'mobile-menu-btn-close';
export const selectedClassName = 'selected';
export const squaredBrandPath = '/brands/squared';
