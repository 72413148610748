var $class="se2--utilities",$name="utilities",$path="app/components/utilities/redefineTargetAttributeOfSustainabilityLink.js",$this={$class,$name,$path,};const chevronSelector = '.button-text span';
const newTabIconClassName = 'se2-icon-new-tab';
const sameTabIconClassName = 'se2-icon-arrow-common';

function redefineTargetAttributeOfSustainabilityLink(link) {
  if (link?.href.includes('/ww/en') && window.location.pathname.includes('/ww/en')) {
    // Open sustainability page on the same tab on global corporate page.
    // "_blank" value is set by default for links with /ww/en/ country/language codes
    // to open a sustainability page in new window from non-corporate page.
    link.target = '_self';
    const chevron = link.querySelector(chevronSelector);
    chevron?.classList.remove(newTabIconClassName);
    chevron?.classList.add(sameTabIconClassName);
  }
}

export default redefineTargetAttributeOfSustainabilityLink;
