var $class="se2--header-se",$name="HeaderSE",$path="app/components/HeaderSE/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import 'app/modules/sso';
import calculateTotalStickyHeight from 'app/modules/sticky/calculateTotalStickyHeight';
import initializeSticky from 'app/modules/sticky/initializeSticky';
import recalculateStickyPositions from 'app/modules/sticky/recalculateStickyPositions';

import 'app/partials/image';

import { isHeaderVersion8 } from 'app/components/HeaderSE/utilities/isHeaderVersion8';
import isTransparentHeader from 'app/components/utilities/isTransparentHeader';
import setCSSVariable from 'app/utilities/setCSSVariable';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';

import {
  metabarHeightValue,
  focusableElementsSelector,
  metabarHeightValueVersion8,
} from './config';

import './deferrals/brandSelector';
import './partials/commonPart';
import {
  commonHeaderPartClassName,
  sustainabilityLinkClassName,
} from './partials/commonPart/config';
import './deferrals/countrySelector';
import './deferrals/countrySelectorModal';
import './partials/countrySelectorBanner';
import './partials/geolocationBanner';
import './partials/megaMenu';
import './partials/metabar';
import { metabarClassName, paramName, regExpGetHref, selectorAPCCountrySelector } from './partials/metabar/config';
import './partials/promoBanner';
import './partials/search';
import './partials/signInForAPC';
import './partials/skipNavigation';
import './partials/userMenuForAPC';
import './partials/loginBanner';
import './partials/loginModalForMobile';
import './partials/loginForMobile';

const rightSideLogoClassName = 'with-right-side-logo';
const logoSEDomainImgMobileBlockSelector = '.mobile-block-wrap';
const logoSEDomainImgWrapperSelector = '.logo-se-link .logo-standalone';
const logoSEDomainImgSelector = '.logo-se-link .logo-standalone img';
const logoLinkSelector = '.logo-se-link.branding';
const logoBrandLinkSelector = '.logo-brand-link';
const logoBrandWrapperSelector = '.logo-brand';
const menuSectionNameSelector = '.mm-l1-section-name';
const reducedHeaderClass = 'reduced';
const animatedLogoWrapperWidth = 240;
const animatedLogoWidth = 182;
const animatedMenuSectionFontSize = 16;
const sustainabilityLogoBreakpoint = 1200;

export default shell.registerComponent($this, ({
  addEventListener, configs, mount, publishMessage, subscribeToMessage,
}) => {
  let headerElement = null;
  let headerCommonPartElement = null;
  let metabarElement = null;
  let sustainabilityLink = null;
  let isTransparent = false;
  let metabarHeightValueForAnimation = null;

  const getNodes = (element) => {
    headerElement = element;
    headerCommonPartElement = document.querySelector(`.${commonHeaderPartClassName}`);
    metabarElement = headerElement.querySelector(`.${metabarClassName}`);
    sustainabilityLink = headerCommonPartElement.querySelector(`.${sustainabilityLinkClassName}`);
  };

  metabarHeightValueForAnimation = isHeaderVersion8() ? metabarHeightValueVersion8 : metabarHeightValue;

  const moveHeaderByScroll = () => {
    const logoLinkElement = headerCommonPartElement.querySelector(logoLinkSelector);
    const logoSEDomainImgWrapperElement = headerCommonPartElement.querySelector(logoSEDomainImgWrapperSelector);
    const logoSEDomainImgMobileBlockElement = headerCommonPartElement.querySelector(logoSEDomainImgMobileBlockSelector);
    const logoSEDomainImgElement = headerCommonPartElement.querySelector(logoSEDomainImgSelector);
    const logoBrandLinkElement = headerCommonPartElement.querySelector(logoBrandLinkSelector);
    const logoBrandWrapperElement = headerCommonPartElement.querySelector(logoBrandWrapperSelector);
    const menuSectionNameElement = headerCommonPartElement.querySelector(menuSectionNameSelector);

    ScrollTrigger.saveStyles(
      `${logoSEDomainImgSelector},
      ${logoSEDomainImgWrapperSelector},
      ${logoSEDomainImgMobileBlockSelector},
      ${logoLinkSelector},
      ${logoBrandLinkSelector},
      ${logoBrandWrapperSelector},
      ${menuSectionNameSelector},
      .${commonHeaderPartClassName}`,
    );

    ScrollTrigger.matchMedia({
      '(min-width: 1200px)': () => {
        const timeLine = gsap.timeline(
          {
            scrollTrigger: {
              trigger: headerCommonPartElement,
              start: 0,
              end: metabarHeightValueForAnimation,
              scrub: 0.5,
              anticipatePin: 1,
              onEnter: () => {
                publishMessage(configs.messages.headerInMoving);
              },
              onUpdate: throttle(recalculateStickyPositions, 100),
              onLeave: () => {
                headerElement.classList.add(reducedHeaderClass);
                calculateTotalStickyHeight();
                publishMessage(configs.messages.headerHeight, true);
                publishMessage(configs.messages.breadcrumbs, { isVisible: true });
              },
              onEnterBack: () => {
                headerElement.classList.remove(reducedHeaderClass);
                publishMessage(configs.messages.headerHeight, false);
                publishMessage(configs.messages.breadcrumbs, { isVisible: false });
              },
              onScrubComplete: recalculateStickyPositions,
            },
          },
        );

        headerCommonPartElement
        && timeLine.to(headerCommonPartElement, { y: -metabarHeightValueForAnimation }, 0);
        metabarElement
        && timeLine.to(metabarElement, { y: -metabarHeightValueForAnimation }, 0);
        isHeaderVersion8() && logoSEDomainImgWrapperElement
        && timeLine.to(logoSEDomainImgWrapperSelector, { width: animatedLogoWrapperWidth }, 0);
        isHeaderVersion8() && logoSEDomainImgMobileBlockElement
        && timeLine.to(logoSEDomainImgMobileBlockSelector, { width: animatedLogoWrapperWidth }, 0);
        logoSEDomainImgElement
        && timeLine.to(logoSEDomainImgSelector, { width: animatedLogoWidth }, 0);
        logoLinkElement
        && timeLine.to(logoLinkSelector, { top: -8, left: -28, scale: 0.7 }, 0);
        logoBrandLinkElement
        && timeLine.to(logoBrandLinkSelector, { top: metabarHeightValueForAnimation, left: -28, scale: 0.7 }, 0);
        logoBrandWrapperElement
        && timeLine.to(logoBrandWrapperSelector, { padding: 0 }, 0);
        menuSectionNameElement
        && timeLine.to(menuSectionNameSelector, { fontSize: animatedMenuSectionFontSize }, 0);
      },
    });
  };

  const announceHeaderHeight = () => {
    // double-check in case of initializing transparent header, but need take into account in window resize
    if (isTransparent) {
      setCSSVariable(
        configs.css.variableNames.headerHeightDesktop,
        `${metabarHeightValueForAnimation}px`,
      );
    } else {
      setTimeout(() => setCSSVariable(
        configs.css.variableNames.headerHeightDesktop,
        `${headerElement.clientHeight}px`,
      ), 0);
    }

    throttle(() => {
      setCSSVariable(
        configs.css.variableNames.headerHeightReduced,
        `${headerCommonPartElement.clientHeight}px`,
      );
    }, 100);
  };

  const toggleSustainabilityLogo = () => {
    const isSustainabilityLogoBreakpoint = window.innerWidth >= sustainabilityLogoBreakpoint;

    if (sustainabilityLink) {
      headerCommonPartElement
        ?.classList
        .toggle(rightSideLogoClassName, isSustainabilityLogoBreakpoint);
    }
  };

  const onWindowResize = () => {
    if (!headerElement) return;

    if (window.innerWidth <= configs.breakpoints.xs.max) {
      headerElement.classList.remove(reducedHeaderClass);
    } else {
      announceHeaderHeight();
    }

    toggleSustainabilityLogo();
  };

  const onFocusHeaderMessage = () => {
    const activePart = (window.innerWidth <= configs.breakpoints.xs.max)
      ? headerCommonPartElement : metabarElement;

    const focusableHeaderElement = activePart.querySelector(focusableElementsSelector);
    focusableHeaderElement.focus();
  };

  addEventListener(window, 'resize', onWindowResize);

  const refererLink = (href) => {
    const { location } = window;
    const countrySelector = href.match(regExpGetHref);

    if (countrySelector) {
      location.assign(`${location.origin}${countrySelector}${paramName}=${location.pathname}`);
    }
  };

  mount((element) => {
    isTransparent = isTransparentHeader();
    getNodes(element);
    toggleSustainabilityLogo();
    initializeSticky(element);
    announceHeaderHeight();
    moveHeaderByScroll();
    subscribeToMessage(configs.messages.focusHeader, onFocusHeaderMessage);
    publishMessage(configs.messages.headerMount);
    publishMessage(configs.messages.refreshTimelines);

    // need to LinkListAPCCountrySelector for HeaderAPC
    forEach(
      element.querySelectorAll(selectorAPCCountrySelector),
      (item) => addEventListener(item, 'click', (e) => {
        e.preventDefault();
        refererLink(item.getAttribute('href'));
      }),
    );
  });
});
