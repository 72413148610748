var $class="se2--header-se--metabar",$name="HeaderSE/metabar",$path="app/components/HeaderSE/partials/metabar/index.js",$this={$class,$name,$path,};/* eslint-disable no-unused-vars */
import { stringValueFormaxNumberInCounter,
  dataAttributeTime,
  countAttribute,
  stockPriceSelector,
  priceDiffSelector,
  priceTextSelector,
  priceInfoSelector } from 'app/components/HeaderSE/partials/metabar/config';

import ajax from 'app/modules/ajax';
import shell from 'app/modules/shell';

import getCookie from 'app/utilities/getCookie';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import { css, delays } from 'configs';

import forEach from 'lodash/forEach';

const shoppingCartCookie = {
  name: null,
  value: null,
};
const documentCookie = {
  name: null,
  value: null,
};
const maxNumberInCounter = 100;
const productPartCartCookie = 'PESV2_Cart_';
const infixB2C = 'B2C_';
const documentPartCartCookie = 'DDC_Cart_';

const shoppingCart = '.shopping-cart';
// TODO fix docs counter
const myDocsSelector = '.favorite-docs';
const loginWrapSelector = '.login-wrap';
const loginWrapClassName = 'login-wrap';
const loginLinkSelector = '.login-link';
const loginBtnSelector = '.login-btn';
const logoutBtnSelector = '.login-logout';
const loginBtnTextSelector = '.login-btn > span';
const b2cPageClassName = '.b2c-page';

let shoppingCartsElements = null;
let myDocsElement = null;
let loginWrapElements = null;

export const setSharePrice = () => {
  // need to find wrappers in xl and xs and get url to server for gaining information
  const stockPriceElements = document.querySelectorAll(stockPriceSelector);
  let serviceUrl;
  stockPriceElements.length && ({ serviceUrl } = stockPriceElements[0].dataset);

  if (serviceUrl) {
    ajax.fetchJSON(serviceUrl).then(
      (data) => {
        forEach(
          stockPriceElements,
          (price) => {
            price.querySelector(priceTextSelector).classList.add(css.classNames.visuallyHidden);
            price.querySelector(priceInfoSelector).style.display = 'block';
            // need to use setAttribute, since if we use dataset, page doesn't refresh for a long time in ie
            data.date && price.setAttribute(dataAttributeTime, data.date.replace(/&nbsp;/g, ' '));

            // need to set indicator as a data attribute for changing arrow direction
            data.indicator && (price.dataset.indicator = data.indicator);
            const wrapPriceDiff = price.querySelector(priceDiffSelector);

            if (wrapPriceDiff) {
            // need to push information from server as a html, since we get HTML code for euro in JSON
              data.value && wrapPriceDiff.insertAdjacentHTML('beforebegin', data.value);
              data.var && (wrapPriceDiff.innerHTML = data.var);
            }
          },
        );
      },
      () => {},
    );
  }
};

const getCookiesFullName = (element, { countryCode, languageCode }) => {
  // eslint-disable-next-line no-param-reassign
  countryCode = countryCode.toUpperCase();
  const isB2C = element.classList.contains(b2cPageClassName);
  shoppingCartCookie.name = `${productPartCartCookie}${
    isB2C ? infixB2C : ''}${languageCode}_${countryCode}`;
  documentCookie.name = `${documentPartCartCookie}${languageCode}_${countryCode}`;
};

// if cookieValue >= 100, should be shown string 99+, instead of cookieValue
const capNumberValue = (cookieValue) => ((cookieValue >= maxNumberInCounter)
  ? stringValueFormaxNumberInCounter
  : cookieValue);

export const setNumberValue = (items, cookie) => {
  // use this, in reason that cookies API no supported by Safari
  const currentCookieValue = cookie.name && getCookie(cookie.name);
  if (items.length && cookie.value !== currentCookieValue) {
    forEach(
      items,
      (item) => {
        const counterValue = cookie.name && capNumberValue(currentCookieValue);
        /* If update data-attr value with using dataset IE doesn't understand that value was updated,
        because that for updating data-attr value we use setAttribute here */
        item.setAttribute(countAttribute, counterValue || 0);
        const a11yLabelForCart = item.querySelector('.cart-count');
        if (a11yLabelForCart) {
          a11yLabelForCart.textContent = counterValue;
          a11yLabelForCart.setAttribute('aria-label', counterValue || 0);
        }
        cookie.value = currentCookieValue;
      },
    );
  }
};

const getNodes = (element) => {
  loginWrapElements = document.getElementsByClassName(loginWrapClassName);
  if (element) {
    shoppingCartsElements = element.querySelectorAll(shoppingCart);
    myDocsElement = element.querySelectorAll(myDocsSelector);
  }
};

const initCounter = () => {
  setNumberValue(shoppingCartsElements, shoppingCartCookie);
  // TODO repair it
  setNumberValue(myDocsElement, documentCookie);
};

export default shell.registerPartial($this, ({
  addEventListener, configs, mount, publishMessage, settings, subscribeToMessage,
}) => {
  const setUserData = ({ name, surname }) => {
    for (const login of loginWrapElements) {
      const loginBtnElement = login.querySelector(loginBtnSelector);
      const loginBtnTextElement = login.querySelector(loginBtnTextSelector);
      const logoutBtnElement = login.querySelector(logoutBtnSelector);

      const ssoLogout = () => publishMessage(configs.messages.ssoLogout);
      const openLoginMenu = () => login.classList.toggle(css.classNames.open);

      // set user name in button
      loginBtnTextElement.innerHTML = `${name || ''} ${surname || ''}`;

      addEventListener(logoutBtnElement, 'click', ssoLogout);
      addEventListener(loginBtnElement, 'click', openLoginMenu);

      // need to show buttons with user name
      login.classList.remove(configs.css.classNames.hide);

      // need to hide link, when user is register
      forEach(
        document.querySelectorAll(loginLinkSelector),
        ({ classList }) => classList.add(configs.css.classNames.hide),
      );
    }
  };

  // TODO remove this duplicate
  const closeLoginMenu = (e) => {
    if (!isMobileBreakpoint() && !e?.target.closest(loginWrapSelector)) {
      for (const login of loginWrapElements) {
        if (login.classList.contains(css.classNames.open)) {
          login.classList.remove(css.classNames.open);
        }
      }
    }
  };

  mount((element) => {
    getNodes(element);
    getCookiesFullName(element, settings.page);

    subscribeToMessage(configs.messages.ssoUserNameUpdated, setUserData);
    subscribeToMessage(configs.messages.headerInMoving, closeLoginMenu);

    // need to hide the menu, when user clicks outside it
    addEventListener(window, 'click', closeLoginMenu);

    setSharePrice();
    setInterval(initCounter, delays.s5);
  });
});
