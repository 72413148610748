var $class="se2--utilities",$name="utilities",$path="app/components/utilities/onSignInMenuKeyDown.js",$this={$class,$name,$path,};import { keyNames, css } from 'configs';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';

const onSignInMenuKeyDown = (
  event,
  moduleBlock,
  menuBlock,
  expandedClass,
  menuElement,
  lastMenuElement,
) => {
  const wasteElement = moduleBlock.querySelector('p');
  const { code, target } = event;
  const switchFocusToElement = (focusElement) => {
    event.preventDefault();
    if (focusElement === wasteElement) {
      switchFocusToTargetElement(
        target === lastMenuElement ? menuElement : lastMenuElement,
        target,
      );
    } else {
      switchFocusToTargetElement(
        focusElement || (
          focusElement === target.previousElementSibling
            ? lastMenuElement
            : menuElement),
        target,
      );
    }
  };
  const { arrowDown, arrowUp, escape, tab } = keyNames;

  // eslint-disable-next-line default-case
  switch (code) {
    case tab:
    case escape:
      moduleBlock.classList.remove(css.classNames.open);
      menuBlock.classList.remove(expandedClass);
      event.preventDefault();
      menuBlock?.focus();
      break;
    case arrowDown:
      switchFocusToElement(target.nextElementSibling);
      break;
    case arrowUp:
      switchFocusToElement(target.previousElementSibling);
      break;
  }
};

export default onSignInMenuKeyDown;
