var $class="se2--header-se--country-selector-modal",$name="HeaderSE/countrySelectorModal",$path="app/components/HeaderSE/deferrals/countrySelectorModal/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import {
  deferredMountCountrySelector,
  checkPageExistenseOnOtherLocale,
  createSupposePageUrl,
} from 'app/partials/countrySelectorBase';

import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';
import setCookie from 'app/utilities/setCookie';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import forEach from 'lodash/forEach';

import { geolocation } from 'configs';

import ajax from 'app/modules/ajax';
import { countrySelectorBannerClassName } from 'app/components/HeaderSE/partials/countrySelectorBanner/config';
import { setCookies } from 'app/components/HeaderSE/partials/geolocationBanner';
import {
  countrySelectorSEClassName,
  countrySelectorSESectionSelector,
} from './config';

const shadowClassName = 'shadow';
const listHeadSelector = '.head';
const countryListSelector = '.body';
const acceptButtonSelector = '.button-accept';
const countriesArraySelector = `.${countrySelectorSEClassName} a`;

let isShadowAdded = false;
let isInitialized = false;

export default shell.registerPartial($this, ({
  addEventListener, configs, mount, publishMessage, log, settings, subscribeToMessage,
}) => {
  let countryListElement = null;

  const handleLocationChanging = (regionWrapElement) => {
    addEventListener(regionWrapElement, 'click', (event) => {
      const { target } = event;
      if (!target.href) return;
      event.preventDefault();
      let referrerPage = window.location.pathname;
      const chinaOrigin = window.location.origin.includes('schneider-electric.cn');

      if (referrerPage.includes('africa')) {
        referrerPage = referrerPage.replace('africa', 'xf');
      } else if (chinaOrigin) {
        referrerPage = `/cn${referrerPage}`;
      }

      const { countryCode, languageCode } = getCountryLanguageCodes(target.href);
      referrerPage = createSupposePageUrl(referrerPage, countryCode, languageCode);

      const referrerPageUrlForStatusCheck = new URL(referrerPage, chinaOrigin ? 'se.com' : window.location.origin);
      checkPageExistenseOnOtherLocale(referrerPageUrlForStatusCheck).then((status) => {
        if (status >= 400) {
          window.location.href = target.href;
        } else window.location.href = referrerPage;
      }).catch((error) => {
        log.error(`Error in coutrySelectorSE, ${referrerPage}`, error);
        window.location.href = target.href;
      });
    });
  };

  const showCountrySelectorMenuOnClick = (countrySelectorElement) => {
    const loaderClassName = 'se2-icon-loader';
    const placeholderElement = countrySelectorElement;

    if (placeholderElement) {
      const { path } = placeholderElement?.dataset;

      placeholderElement.classList.add(loaderClassName);

      return ajax.fetchDeferralTo(path, placeholderElement)
        .then(() => document.body.appendChild(placeholderElement))
        .then(() => placeholderElement.classList.remove(loaderClassName));
    }
    return Promise.reject();
  };

  const detectBrowser = (countrySelectorElement) => {
    if (settings.platform.browser === configs.browsers.safari) {
      forEach(
        countrySelectorElement.querySelectorAll(countriesArraySelector),
        (country) => country.setAttribute('role', 'menuitem'),
      );
    }
  };

  const toggleCountrySelectorVisibility = (countrySelectorElement, isHidden) => {
    countrySelectorElement.classList.toggle(configs.css.classNames.hide, isHidden);
  };

  const closeCountrySelector = (countrySelectorElement) => {
    countrySelectorElement.classList.add(configs.css.classNames.hide);
    clearAllBodyScrollLocks();
  };

  const fixedCountrySelectorTitle = (countrySelectorElement) => {
    const countryList = countrySelectorElement.querySelector(countryListSelector);
    if (!countryList) return;
    const listHead = countrySelectorElement.querySelector(listHeadSelector);

    addEventListener(countryList, 'click', ({ target }) => {
      if (target.hasAttribute('href')) {
        setCookie(geolocation.cookies.countrySelected, true, {
          path: '/',
          expires: geolocation.baseCookieExpire,
        });
        const { countryCode: selectedCountry } = getCountryLanguageCodes(target.href);

        setCookie(`${geolocation.geolocationDNDCookie}_${selectedCountry}`,
          true,
          {
            path: '/',
          });
      }
    });

    if (!listHead) return;
    addEventListener(countryList, 'scroll', () => {
      if (isShadowAdded && countryList.scrollTop === 0) {
        listHead.classList.remove(shadowClassName);
        isShadowAdded = false;
      } else if (!isShadowAdded && countryList.scrollTop > 0) {
        listHead.classList.add(shadowClassName);
        isShadowAdded = true;
      }
    });
  };

  const handleNavigation = (e, countrySelectorElement, countrySelectorBannerButton) => {
    const { code } = e;

    if (code === configs.keyNames.escape) {
      closeCountrySelector(countrySelectorElement);
      countrySelectorBannerButton.focus();
    }
  };

  const openCountrySelector = (countrySelectorElement, countrySelectorBannerButton) => {
    toggleCountrySelectorVisibility(countrySelectorElement, false);
    showCountrySelectorMenuOnClick(countrySelectorElement)
      .then(() => {
        publishMessage(configs.messages.focusFirstRegionOfCountrySelector);

        countryListElement = countrySelectorElement.querySelector(countryListSelector);

        disableBodyScroll(countryListElement);

        deferredMountCountrySelector(
          countrySelectorElement,
          countrySelectorSESectionSelector,
          configs,
          addEventListener,
          subscribeToMessage,
        );

        fixedCountrySelectorTitle(countrySelectorElement);

        if (isInitialized) return;

        const countrySelectorBannerCloseButton = countrySelectorElement.querySelector(
          '.button-close',
        );

        const countrySelectorBannerExpandButton = countrySelectorElement.querySelector(
          '.accordion',
        );

        const countrySelectorBannerExpandButtonSVG = countrySelectorElement.querySelector(
          '.accordion .se2-icon-arrow-common',
        );

        const countrySelectorModalCountriesContainer = countrySelectorElement.querySelector(
          '.body',
        );

        addEventListener(countrySelectorBannerCloseButton,
          'click',
          () => closeCountrySelector(countrySelectorElement));

        addEventListener(countrySelectorBannerExpandButton, 'click', () => {
          countrySelectorBannerExpandButtonSVG.classList.toggle('rotate');
          countrySelectorModalCountriesContainer.classList.toggle(configs.css.classNames.hide);
        });

        addEventListener(countryListElement, 'keydown',
          (event) => handleNavigation(event, countrySelectorElement, countrySelectorBannerButton));
      },
      () => {})
      // eslint-disable-next-line no-return-assign
      .finally(() => isInitialized = true);
  };

  const toggleCountrySelector = (countrySelectorElement, countrySelectorBannerButton) => {
    openCountrySelector(countrySelectorElement, countrySelectorBannerButton);
    publishMessage(configs.messages.focusFirstRegionOfCountrySelector);
  };

  // const handleOutFromCountrySelector = (e, countrySelectorElement, countrySelectorBannerButton) => {
  //   countryListElement = countrySelectorElement.querySelector(countryListSelector);
  //   e.preventDefault();
  //
  //   if (e.relatedTarget === countrySelectorBannerButton || countrySelectorBannerButton
  //     .contains(e.relatedTarget)) return;
  //   if (!countryListElement.contains(e.relatedTarget || e.target)) {
  //     closeCountrySelector(countrySelectorElement);
  //   }
  // };

  const findCountryLink = (element) => {
    const linkListArray = Array.from(element.querySelectorAll('.link-list a'));
    // const countryCode = window.navigator.language.replace(/[a-zA-Z]+-/gm, '');
    const countryCode = 'br';
    const countryLink = linkListArray.find(({ href }) => href.indexOf(`/${countryCode.toLowerCase()}/`) >= 0);
    return ({
      countryLink,
      countryName: countryLink?.innerText.trim(),
    });
  };

  const setCountryName = (countrySelectorLabel, element) => {
    if (countrySelectorLabel) {
      countrySelectorLabel.innerText = countrySelectorLabel.innerText
        .replace(/{[a-zA-Z]+}/gm, findCountryLink(element).countryName);
    }
  };

  mount((element) => {
    const countrySelectorBannerButton = document.querySelector(`.${countrySelectorBannerClassName}`);
    const acceptButtonElement = element.querySelector(acceptButtonSelector);
    const countrySelectorLabel = element.querySelector('.button-choose');
    if (findCountryLink(element).countryLink) setCountryName(countrySelectorLabel, element);
    if (!findCountryLink(element).countryLink) countrySelectorLabel?.classList.add(configs.css.classNames.hide);
    detectBrowser(element);
    handleLocationChanging(element);

    // addEventListener(element,
    //   'focusout',
    //   (event) => handleOutFromCountrySelector(event, element, countrySelectorBannerButton));

    addEventListener(countrySelectorLabel, 'click', () => findCountryLink(element).countryLink.click());
    addEventListener(acceptButtonElement, 'click', () => {
      setCookies(
        configs.geolocation.setCookieKeywords.countrySelect,
        configs,
      );
      closeCountrySelector(element);
    });
    addEventListener(countrySelectorBannerButton,
      'keydown',
      (event) => handleNavigation(event, element, countrySelectorBannerButton));

    subscribeToMessage(configs.messages.openCountrySelectorModal,
      () => toggleCountrySelector(element, countrySelectorBannerButton));
  });
});
