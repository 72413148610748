var $class="se2--header-se--login-modal-for-mobile",$name="HeaderSE/loginModalForMobile",$path="app/components/HeaderSE/partials/loginModalForMobile/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import { classNames } from 'configs/css';
import keyNames from 'configs/keyNames';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import forEach from 'lodash/forEach';

import {
  chartInfoBlockClassName,
  closeButtonClassName,
  modalClassName,
  modalElementClassName,
  modalWrapClassName,
  modalWrapOverlayClassName,
  placeholderClassName,
} from './config';

function renderModalContainer() {
  const modalTemplate = `
    <div class="${modalClassName} ${modalWrapOverlayClassName} se-reset-css hide">
      <div class="${modalWrapClassName}">
        <div class="${modalElementClassName}" role="dialog" aria-modal="true" tabindex="0">
          <div class="${placeholderClassName}"></div>
          <button
           type="button"
            class="${closeButtonClassName} modal-close-btn se2-icon-close"
             aria-label="Close modal">
          </button>
        </div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML('beforeend', modalTemplate);

  const modal = document.body.querySelector(`.${modalClassName}`);
  const closeButtonElement = modal.querySelector(`.${closeButtonClassName}`);
  const placeholderElement = modal.querySelector(`.${placeholderClassName}`);
  const modalElement = modal.querySelector(`.${modalElementClassName}`);
  const wrapElement = modal.querySelector(`.${modalWrapClassName}`);

  return {
    closeButtonElement,
    placeholderElement,
    modal,
    modalElement,
    wrapElement,
  };
}

export default shell.registerPartial($this, ({ addEventListener }) => {
  let isVisible = false;
  let initialButton;

  const {
    closeButtonElement,
    placeholderElement,
    modal,
    modalElement,
    wrapElement,
  } = renderModalContainer();

  function hideModal() {
    modal.classList.remove(classNames.expanded);
    modal.classList.add(classNames.hide);
    placeholderElement.innerHTML = '';
    isVisible = false;
    initialButton?.focus();
  }

  function showModal({
    appendHTML = '',
    isCharts = false,
    labelCloseButton = '',
    target = null,
  } = {}) {
    isVisible = true;
    wrapElement.classList.toggle(chartInfoBlockClassName, isCharts);
    placeholderElement.insertAdjacentHTML('beforeend', appendHTML);
    if (labelCloseButton) closeButtonElement.setAttribute('aria-label', labelCloseButton);
    modal.classList.remove(classNames.hide);
    initialButton = target;
    modalElement.setAttribute('aria-label', (initialButton?.innerText === undefined) ? '' : initialButton?.innerText);
    return modal;
  }

  function getModal() {
    return modal;
  }

  addEventListener(closeButtonElement, 'keydown', (e) => {
    if (isVisible && e.keyCode === 9 && !e.shiftKey) {
      e.preventDefault();
      modalElement.focus();
    }
  });

  addEventListener(modal, 'click', ({ target }) => {
    const placeholderElementChildren = placeholderElement.children;
    const placeholderChildrenArray = Array.isArray(placeholderElementChildren)
      ? placeholderElementChildren : Array.from(placeholderElementChildren);

    let isChildTarget = false;

    forEach(placeholderChildrenArray, (child) => {
      if (child.contains(target)) {
        isChildTarget = true;
      }
    });

    if ((target !== placeholderElement && !isChildTarget) || (target === closeButtonElement)) {
      hideModal();
    }
  });
  addEventListener(document, 'keydown', ({ code }) => {
    if (code === keyNames.escape) {
      hideModal();
    }
  });

  const onWindowResize = () => {
    if (!isMobileBreakpoint()) {
      hideModal();
    }
  };

  addEventListener(window, 'resize', onWindowResize);

  return {
    isVisible,
    getModal,
    hideModal,
    placeholderElement,
    showModal,
    wrapElement,
  };
});
