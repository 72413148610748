var $class="se2--header-se--common-part",$name="HeaderSE/commonPart",$path="app/components/HeaderSE/partials/commonPart/config.js",$this={$class,$name,$path,};// eslint-disable-next-line import/prefer-default-export
export const closeMobileMenuButtonSelector = '.mobile-menu-btn-close';
export const commonHeaderPartClassName = $class;
export const globalSearchAppId = 'GlobalSearchApp';
export const openMobileMenuButtonSelector = '.mobile-menu-btn-open';
export const sustainabilityLinkClassName = 'sustainability-logo-link';
export const menuNavButtonSelector = '.menu-nav-button';
export const headerHeightMobileCSSVariable = '--header-height-mobile';
export const menuWrapSelector = '.mm-wrap';
export const menuSectionSelector = '.mm-tab';
export const menuL1ItemsSelector = '.mm-l1-item';
export const seeAllL1LinksSelector = '.mm-cta-wrap';
export const menuL1ButtonClassName = 'mm-l1-button-popup';
export const menuL2ButtonSelector = '.mm-l2-button';
export const menuL2ListSelector = '.mm-l2-list';
export const menuL2ItemsSelector = '.mm-l2-item';
export const menuL2ItemsLink = '.mm-l2-link';
export const menuL3ItemsSelector = '.mm-l3-list';
export const menuWrapperSimplifiedSelector = '.menu-wrapper-simplified';
export const mobileHeaderHeightWithGlobalSearch = 60;
export const mobileMetabarSelector = '.mobile-metabar';
export const promoCtaSelector = '.mm-promo-cta';
export const b2bB2cToggleMobileSelector = '.mob-b2bB2cToggle';
export const loaderClassName = 'se2-icon-loader';
export const logoSELinkSelector = '.logo-se-link';
export const mobileBlockWrapSelector = '.mobile-block-wrap';
export const nestedCountrySelectorSelector = '.stubbed-content';
export const startChinaLink = 'https://www.schneider-electric.cn/zh/';
export const b2cPageClassName = '.b2c-page';
export const myDocsSelectorMobile = '.label-wrapper';
export const maxNumberInCounter = 100;
export const stringValueFormaxNumberInCounter = '99+';
export const productPartCartCookie = 'PESV2_Cart_';
export const infixB2C = 'B2C_';
export const documentPartCartCookie = 'DDC_Cart_';
export const countAttribute = 'data-count';
export const countAttributeMobile = 'data-count-mobile';
export const updateCounter = '.cart-count';
export const updateCounterMobile = '.cart-count-mobile';
export const shoppingCart = '.shopping-cart-counter';
export const shoppingCartAPC = '.shopping-cart';
export const shoppingCartMobile = '.shopping-cart-counter-mobile';
export const expandedMenuClassName = 'expanded-menu';
export const megaMenuSe = '.mm-l1-list';
export const headerSectionListItem = '.js-header-section-list-item';
export const headerSectionListItemContent = '.mm-tab';
export const menulevel1Header = '.js-header_mm-l1-button-popup';
export const menuType = 'Navigation';
