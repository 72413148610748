var $class="se2--header-se--sign-in-for-apc",$name="HeaderSE/signInForAPC",$path="app/components/HeaderSE/partials/signInForAPC/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import onSignInMenuKeyDown from 'app/components/utilities/onSignInMenuKeyDown';
import popupWidth from './config';

const signInButtonSelector = '.sign-in-button';
const signInMenuSelector = '.sign-in-menu';
const expandedClassName = 'is-expanded';
const menuLinkSelector = '.sign-in-menu a';
const lastMenuLinkSelector = '.register-now-link';

export default shell.registerComponent($this, ({ addEventListener, configs, mount }) => {
  mount((element) => {
    const signInButtonElement = document.querySelector(signInButtonSelector);
    const signInMenuElement = element.querySelector(signInMenuSelector);
    const menuLinkElement = document.querySelector(menuLinkSelector);
    const lastMenuLinkElement = document.querySelector(lastMenuLinkSelector);
    signInMenuElement.style.left = `${signInButtonElement.getBoundingClientRect().right - popupWidth}px`;
    const createOnSignInMenuKeyDown = () => (event) => onSignInMenuKeyDown(
      event,
      element,
      signInButtonElement,
      expandedClassName,
      menuLinkElement,
      lastMenuLinkElement,
    );

    addEventListener(signInButtonElement, 'click', () => {
      signInMenuElement.style.left = `${signInButtonElement.getBoundingClientRect().right - popupWidth}px`;
      element.classList.toggle(configs.css.classNames.open);
      signInButtonElement.classList.toggle(expandedClassName);
      menuLinkElement?.focus();
    });

    addEventListener(
      signInMenuElement,
      'keydown',
      createOnSignInMenuKeyDown(),
    );

    addEventListener(window, 'click', ({ target }) => {
      if (target !== signInButtonElement && target.parentNode !== signInButtonElement) {
        element.classList.remove(configs.css.classNames.open);
        signInButtonElement.classList.remove(expandedClassName);
      }
    }, true);

    addEventListener(window, 'scroll', () => {
      element.classList.remove(configs.css.classNames.open);
      signInButtonElement.classList.remove(expandedClassName);
    });
  });
});
