var $class="se2--header-se--brand-selector",$name="HeaderSE/brandSelector",$path="app/components/HeaderSE/deferrals/brandSelector/utilities/brandSelectorShared.js",$this={$class,$name,$path,};import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import { css } from 'configs/index';

import {
  brandListClassName,
  desktopBrandSelectorClassName,
  expandedClassName,
  listHeadClassName,
  mobileBrandSelectorClassName,
} from '../config';

const toggleBrandSelectorAttribute = (button) => {
  button.classList.toggle(expandedClassName);
  button.setAttribute(
    'aria-expanded',
    button.classList.contains(expandedClassName),
  );
};

const setLeftPositionToBrandList = ({ style }, brandSelectorElement) => {
  if (!isMobileBreakpoint() && !style.left) {
    style.left = `${brandSelectorElement.getBoundingClientRect().left}px`;
  }
};

const toggleBrandListVisibility = (element) => {
  const brandListElement = document.querySelector(`.${brandListClassName}`);
  const desktopBrandSelectorElement = document.querySelector(`.${desktopBrandSelectorClassName}`);
  const mobileBrandSelectorElement = document.querySelector(`.${mobileBrandSelectorClassName}`);

  toggleBrandSelectorAttribute(mobileBrandSelectorElement);
  toggleBrandSelectorAttribute(desktopBrandSelectorElement);

  brandListElement.classList.toggle(css.classNames.open);
  element.classList.toggle(css.classNames.hide);
  setLeftPositionToBrandList(brandListElement, desktopBrandSelectorElement);

  element
    .querySelector(`.${listHeadClassName}`)
    ?.classList
    .toggle(css.classNames.hide, !isMobileBreakpoint());

  if (element.classList.contains(css.classNames.hide)) {
    (isMobileBreakpoint() ? mobileBrandSelectorElement : desktopBrandSelectorElement).focus();
  }
};

export default toggleBrandListVisibility;
