var $class="se2--header-se--user-menu-for-apc",$name="HeaderSE/userMenuForAPC",$path="app/components/HeaderSE/partials/userMenuForAPC/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import onSignInMenuKeyDown from 'app/components/utilities/onSignInMenuKeyDown';
import popupWidth from './config';

const userMenuAPCButtonSelector = '.user-menu-button';
const signInMobileAPCLinkSelector = '.apc-login-link-mobile';
const userAccountAPCMobileLinkSelector = '.apc-account-link-mobile';
const userMenuSelector = '.user-menu';
const expandedClassName = 'is-expanded';
const menuLinkSelector = '.user-menu a';
const lastMenuLinkSelector = '.logout-link';

export default shell.registerComponent($this, ({ addEventListener, configs, mount, subscribeToMessage }) => {
  mount((element) => {
    const signInButtonElement = document.querySelector(userMenuAPCButtonSelector);
    const signInMobileLinkElement = element.querySelector(signInMobileAPCLinkSelector);
    const userAccountMobileLinkElement = element.querySelector(userAccountAPCMobileLinkSelector);
    const userMenuElement = element.querySelector(userMenuSelector);
    const menuLinkElement = element.querySelector(menuLinkSelector);
    const lastMenuLinkElement = element.querySelector(lastMenuLinkSelector);
    const changeLinkForMobile = () => {
      signInMobileLinkElement.classList.add(configs.css.classNames.hide);
      userAccountMobileLinkElement.classList.remove(configs.css.classNames.hide);
    };
    const createOnSignInMenuKeyDown = () => (event) => onSignInMenuKeyDown(
      event,
      element,
      signInButtonElement,
      expandedClassName,
      menuLinkElement,
      lastMenuLinkElement,
    );

    addEventListener(signInButtonElement, 'click', () => {
      userMenuElement.style.left = `${signInButtonElement.getBoundingClientRect().right - popupWidth}px`;
      element.classList.toggle(configs.css.classNames.open);
      signInButtonElement.classList.toggle(expandedClassName);
      menuLinkElement.focus();
    });

    addEventListener(
      userMenuElement,
      'keydown',
      createOnSignInMenuKeyDown(),
    );

    addEventListener(window, 'click', ({ target }) => {
      if (target !== signInButtonElement && target.parentNode !== signInButtonElement) {
        element.classList.remove(configs.css.classNames.open);
        signInButtonElement.classList.remove(expandedClassName);
      }
    }, true);

    addEventListener(window, 'scroll', () => {
      element.classList.remove(configs.css.classNames.open);
      signInButtonElement.classList.remove(expandedClassName);
    });

    subscribeToMessage(configs.messages.ssoUserNameUpdated, changeLinkForMobile);
  });
});
