var $class="se2--header-se--country-selector",$name="HeaderSE/countrySelector",$path="app/components/HeaderSE/deferrals/countrySelector/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import {
  deferredMountCountrySelector,
  checkPageExistenseOnOtherLocale,
  createSupposePageUrl,
} from 'app/partials/countrySelectorBase';

import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import setCookie from 'app/utilities/setCookie';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import forEach from 'lodash/forEach';

import { geolocation } from 'configs';

import ajax from 'app/modules/ajax';
import {
  countrySelectorSEClassName,
  countrySelectorSESectionSelector,
} from './config';
import { metabarClassName } from '../../partials/metabar/config';

const shadowClassName = 'shadow';
const expandedClassName = 'is-expanded';
const listHeadSelector = '.head';
const countryListSelector = '.body';
const countrySelectorDesktopTogglerSelector = `.${metabarClassName} .country-selector-toggler`;
const countrySelectorMobileTogglerSelector = '.mobile-metabar .country-selector-toggler';
const countriesArraySelector = `.${countrySelectorSEClassName} a`;
const closeButtonSelector = '.button-close';
const preferredCountry = 'preferredCountry';

let isShadowAdded = false;

export default shell.registerPartial($this, ({
  addEventListener, configs, mount, publishMessage, log, settings, subscribeToMessage,
}) => {
  const accessories = new Map();

  let countrySelectorDesktopTogglerElement = null;
  let countrySelectorMobileTogglerElement = null;
  let countryListElement = null;

  const handleSearchParams = (url, referrerPage) => {
    const urlNow = new URL(url);
    const referrerPageUrl = `${urlNow.origin}${referrerPage}`;
    const urlNext = new URL(referrerPageUrl);

    if (urlNow.searchParams.has(preferredCountry)) {
      urlNow.searchParams.delete(preferredCountry);
    }

    urlNow.searchParams.forEach((value, key) => {
      urlNext.searchParams.append(key, value);
    });

    return `${urlNext.origin}${urlNext.pathname}${urlNext.search}`;
  };

  const handleLocationChanging = (regionWrapElement) => {
    addEventListener(regionWrapElement, 'click', (event) => {
      const { target } = event;
      if (!target.href) return;
      event.preventDefault();
      let referrerPage = window.location.pathname;
      const chinaOrigin = window.location.origin.includes('schneider-electric.cn');

      if (referrerPage.includes('news/press-releases')) {
        referrerPage = `${referrerPage.split('newsroom')[0]}newsroom`;
      }

      if (referrerPage.includes('africa')) {
        referrerPage = referrerPage.replace('africa', 'xf');
      } else if (chinaOrigin) {
        referrerPage = `/cn${referrerPage}`;
      }

      if (target.href.includes('africa')) {
        target.href = target.href.replace('africa', 'xf');
      }

      const { countryCode, languageCode } = getCountryLanguageCodes(target.href);
      referrerPage = createSupposePageUrl(referrerPage, countryCode, languageCode);
      if (referrerPage.includes('/search/')) {
        referrerPage = handleSearchParams(window.location.href, referrerPage);
      }
      const baseUrl = new URL(chinaOrigin ? 'https://www.se.com' : window.location.origin);
      const referrerPageUrlForStatusCheck = new URL(referrerPage, baseUrl);
      checkPageExistenseOnOtherLocale(referrerPageUrlForStatusCheck).then((status) => {
        if (chinaOrigin) {
          window.location.href = `${baseUrl.origin}${referrerPage}`;
        } else if (status >= 400) {
          window.location.href = target.href;
        } else {
          window.location.href = referrerPage;
        }
      }).catch((error) => {
        log.error(`Error in coutrySelectorSE, ${referrerPage}`, error);
        window.location.href = target.href;
      });
    });
  };

  const getNodes = () => {
    countrySelectorDesktopTogglerElement = document.querySelector(countrySelectorDesktopTogglerSelector);
    countrySelectorMobileTogglerElement = document.querySelector(countrySelectorMobileTogglerSelector);
  };

  const showCountrySelectorMenuOnClick = (countrySelectorElement) => {
    const loaderClassName = 'se2-icon-loader';
    const placeholderElement = countrySelectorElement;

    if (placeholderElement) {
      const { path } = placeholderElement?.dataset;

      placeholderElement.classList.add(loaderClassName);

      return ajax.fetchDeferralTo(path, placeholderElement)
        .then(() => placeholderElement.classList.remove(loaderClassName));
    }
    return Promise.reject();
  };

  const detectBrowser = (countrySelectorElement) => {
    if (settings.platform.browser === configs.browsers.safari) {
      forEach(
        countrySelectorElement.querySelectorAll(countriesArraySelector),
        (country) => country.setAttribute('role', 'menuitem'),
      );
    }
  };

  const getToggleElement = () => (isMobileBreakpoint()
    ? countrySelectorMobileTogglerElement
    : countrySelectorDesktopTogglerElement);

  const toggleCountrySelectorVisibility = (countrySelectorElement, toggleElement, isHidden) => {
    countrySelectorElement.classList.toggle(configs.css.classNames.hide, isHidden);
    toggleElement.setAttribute('aria-expanded', !isHidden);
    toggleElement.classList.toggle(expandedClassName, !isHidden);
  };

  const closeCountrySelector = (countrySelectorElement) => {
    toggleCountrySelectorVisibility(countrySelectorElement, getToggleElement(), true);
    clearAllBodyScrollLocks();
  };

  const fixedCountrySelectorTitle = (countrySelectorElement) => {
    const countryList = countrySelectorElement.querySelector(countryListSelector);
    if (!countryList) return;
    const listHead = countrySelectorElement.querySelector(listHeadSelector);

    addEventListener(countryList, 'click', ({ target }) => {
      if (target.hasAttribute('href')) {
        setCookie(geolocation.cookies.countrySelected, true, {
          path: '/',
          expires: geolocation.baseCookieExpire,
        });
        const { countryCode: selectedCountry } = getCountryLanguageCodes(target.href);
        setCookie(geolocation.cookies.locale, selectedCountry, {
          path: '/',
          expires: geolocation.baseCookieExpire,
        });
        setCookie(`${geolocation.geolocationDNDCookie}_${selectedCountry}`,
          true,
          {
            path: '/',
          });
      }
    });

    if (!listHead) return;
    addEventListener(countryList, 'scroll', () => {
      if (isShadowAdded && countryList.scrollTop === 0) {
        listHead.classList.remove(shadowClassName);
        isShadowAdded = false;
      } else if (!isShadowAdded && countryList.scrollTop > 0) {
        listHead.classList.add(shadowClassName);
        isShadowAdded = true;
      }
    });
  };

  const openCountrySelector = (countrySelectorElement) => {
    toggleCountrySelectorVisibility(countrySelectorElement, getToggleElement(), false);
    showCountrySelectorMenuOnClick(countrySelectorElement)
      .then(() => {
        publishMessage(configs.messages.focusFirstRegionOfCountrySelector);

        countryListElement = countrySelectorElement.querySelector(countryListSelector);

        disableBodyScroll(countryListElement);

        deferredMountCountrySelector(
          countrySelectorElement,
          countrySelectorSESectionSelector,
          configs,
          addEventListener,
          subscribeToMessage,
        );

        fixedCountrySelectorTitle(countrySelectorElement);

        addEventListener(
          countrySelectorElement.querySelector(closeButtonSelector),
          'click',
          accessories.get(countrySelectorElement).closeCountrySelector,
        );
        addEventListener(countryListElement, 'keydown', accessories.get(countrySelectorElement).handleNavigation);
      },
      () => {});
  };

  const toggleCountrySelector = (countrySelectorElement) => {
    if (getToggleElement().classList.contains(expandedClassName)) {
      closeCountrySelector(countrySelectorElement);
    } else {
      openCountrySelector(countrySelectorElement);
      publishMessage(configs.messages.focusFirstRegionOfCountrySelector);
    }
  };

  const handleOutFromCountrySelector = (e, countrySelectorElement) => {
    countryListElement = countrySelectorElement.querySelector(countryListSelector);
    e.preventDefault();
    const toggleElement = getToggleElement();

    if (e.relatedTarget === toggleElement || toggleElement.contains(e.relatedTarget)) return;
    if (!countryListElement.contains(e.relatedTarget || e.target)) {
      closeCountrySelector(countrySelectorElement);
    }
  };

  const handleNavigation = (e, countrySelectorElement) => {
    const { code } = e;

    if (code === configs.keyNames.escape) {
      closeCountrySelector(countrySelectorElement);
      getToggleElement().focus();
    }
  };

  mount((element) => {
    getNodes();
    detectBrowser(element);
    handleLocationChanging(element);

    accessories.set(element, {
      closeCountrySelector: () => closeCountrySelector(element),
      handleNavigation: (e) => handleNavigation(e, element),
      handleOutFromCountrySelector: (e) => handleOutFromCountrySelector(e, element),
      toggleCountrySelector: () => toggleCountrySelector(element),
      openCountrySelectorByGeoBanner: () => openCountrySelector(element),
    });

    addEventListener(
      countrySelectorDesktopTogglerElement,
      'click',
      accessories.get(element).toggleCountrySelector,
    );
    addEventListener(
      countrySelectorMobileTogglerElement,
      'click',
      accessories.get(element).toggleCountrySelector,
    );

    addEventListener(element, 'focusout', accessories.get(element).handleOutFromCountrySelector, true);

    addEventListener(countrySelectorDesktopTogglerElement, 'keydown', accessories.get(element).handleNavigation);
    addEventListener(countrySelectorMobileTogglerElement, 'keydown', accessories.get(element).handleNavigation);

    subscribeToMessage(configs.messages.openCountrySelector, accessories.get(element).openCountrySelectorByGeoBanner);
  });
});
