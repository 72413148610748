var $class="se2--country-selector-base",$name="countrySelectorBase",$path="app/partials/countrySelectorBase/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import ajax from 'app/modules/ajax';

import { countrySelectorSectionSelector } from 'app/partials/countrySelectorSection/config';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import setTabIndex from 'app/utilities/setTabIndex';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';
import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';

import forEach from 'lodash/forEach';
import { countrySelectorSEClassName } from 'app/components/HeaderSE/deferrals/countrySelectorModal/config';

export const deferredMountCountrySelector = (
  element,
  selector,
  configs,
  addEventListener,
  subscribeToMessage,
) => {
  if (element.dataset.countrySelectorInitialized) return;

  element.dataset.countrySelectorInitialized = true;
  const firstCountryInRegionSelector = `${selector} .link-list a:first-child `;
  const firstCountryInRegionElements = element.querySelectorAll(firstCountryInRegionSelector);
  const regionElements = element.querySelectorAll(selector);

  let activeRegionElementIndex = 0;
  let activeRegionFirstElement = null;

  const focusFirstRegionOfCountrySelector = (el = activeRegionFirstElement) => {
    el?.focus();
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  activeRegionFirstElement = firstCountryInRegionElements[activeRegionElementIndex];

  const onRegionNavigation = (e) => {
    const { code, shiftKey, target } = e;

    if (code === configs.keyNames.tab) {
      activeRegionElementIndex += shiftKey ? -1 : 1;

      if (activeRegionElementIndex === firstCountryInRegionElements.length
        || activeRegionElementIndex === -1) {
        activeRegionElementIndex = 0;
      }

      const isCountrySelectorBannerModal = e.target.closest(`.${countrySelectorSEClassName}`);
      if (isMobileBreakpoint() || isCountrySelectorBannerModal) {
        forEach(regionElements, ({ classList }) => classList.remove(configs.css.classNames.open));
        regionElements[activeRegionElementIndex].classList.add(configs.css.classNames.open);
      }
      activeRegionFirstElement = firstCountryInRegionElements[activeRegionElementIndex];
      focusFirstRegionOfCountrySelector(activeRegionFirstElement);
    } else if (code === configs.keyNames.arrowDown) {
      const currentElement = target;
      const nextElement = currentElement.nextElementSibling || currentElement.parentNode.querySelector('a');
      switchFocusToTargetElement(nextElement, currentElement);
    } else if (code === configs.keyNames.arrowUp) {
      const currentElement = target;
      const nextElement = currentElement.previousElementSibling || currentElement.parentNode.lastElementChild;
      switchFocusToTargetElement(nextElement, currentElement);
    } else {
      return; // so not to prevent default
    }
    e.preventDefault();
  };

  forEach(
    firstCountryInRegionElements,
    (tile) => {
      setTabIndex(tile, true);
    },
  );

  const isCountrySelectorBannerModal = element.classList.contains(countrySelectorSEClassName);

  if (isCountrySelectorBannerModal) {
    const sections = element.querySelectorAll('.section');
    const openSection = (target) => {
      const currentSection = target.classList.contains('section') ? target : target.closest('.section');
      if (!currentSection) return;
      currentSection.classList.add('open');
      Array.from(sections)
        .filter((section) => section !== currentSection)
        .forEach((section) => section.classList.remove('open'));
    };

    addEventListener(element, isMobileBreakpoint() ? 'click' : 'click', ({ target }) => openSection(target));
    openSection(sections[0]);
  } else {
    addEventListener(element, 'click', ({ target }) => {
      if (target.href) return;

      if (isMobileBreakpoint()) {
        const region = target.closest(selector);
        if (!region) return;
        const isRegionOpened = region.classList.contains(configs.css.classNames.open);

        if (!isRegionOpened) {
          forEach(
            regionElements,
            ({ classList }) => classList.remove(configs.css.classNames.open),
          );
        }
        region?.classList.toggle(configs.css.classNames.open, !isRegionOpened);
      }
    });
  }

  addEventListener(element, 'keydown', onRegionNavigation);
  subscribeToMessage(configs.messages.focusFirstRegionOfCountrySelector, focusFirstRegionOfCountrySelector);
};

export const checkPageExistenseOnOtherLocale = async (url) => {
  url.searchParams.append('statp', 1);

  const status = await ajax.fetchStatus(url.href);

  return status;
};

export const createSupposePageUrl = (currentUrl, supposedCC, supposedLC) => {
  const referrerCcLcCodes = getCountryLanguageCodes(currentUrl);
  const { countryCode: referrerCC, languageCode: referrerLC } = referrerCcLcCodes;
  const referrerRegex = new RegExp(`/${referrerCC}/${referrerLC}/?`, 'i');
  return currentUrl.replace(referrerRegex, `/${supposedCC}/${supposedLC}/`);
};

export default shell.registerPartial($this, ({ addEventListener, configs, mount, subscribeToMessage }) => {
  mount((element) => deferredMountCountrySelector(
    element,
    countrySelectorSectionSelector,
    configs,
    addEventListener,
    subscribeToMessage,
  ));
});
