var $class="se2--header-se--mega-menu",$name="HeaderSE/megaMenu",$path="app/components/HeaderSE/partials/megaMenu/config.js",$this={$class,$name,$path,};export const closeClassName = 'close';
export const openClassName = 'open';
export const menuItemClassName = 'menu-item';
export const menuNavButtonClassName = 'menu-nav-button';
export const showClassName = 'show';

export const closeDesktopMenuButtonSelector = '.desktop-menu-btn-close';
export const level1ButtonPopupSelector = '.mm-l1-button-popup';
export const level1ItemSelector = '.mm-l1-item';
export const level2ButtonSelector = '.mm-l2-button';
export const nextButtonSelector = '.swiper-button-next';
export const prevButtonSelector = '.swiper-button-prev';
export const menuType = 'Navigation';
