var $class="se2--header-se--search",$name="HeaderSE/search",$path="app/components/HeaderSE/partials/search/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import appendAsyncScriptElement from 'app/utilities/appendAsyncScriptElement';

import forEach from 'lodash/forEach';

import { globalSearchApcUrl } from './config';

const buttonTagName = 'BUTTON';
const globalSearchButtonSelector = '#GlobalSearchApp button';
const globalSearchInputSelector = '#GlobalSearchApp input';
const mobileSearchButtonSelector = '.mobile-search-toggler-btn';
const searchFieldSelector = '.search-field';
const searchFieldSelectorWrapper = '.search-menu-wrapper';
const showSeacrhForMobileHeaderVersion8ClassName = 'show-search-above-mobile-header';
const processedSearchSources = new Set();

const isGlobalSearchUsed = (source) => source === globalSearchApcUrl;

shell.registerPartial($this, ({ addEventListener, log, mount }) => {
  mount((element) => {
    const searchScripts = JSON.parse(element.dataset.searchScripts || '[]');

    const appendSearchScripts = ({ currentTarget }) => {
      forEach(
        searchScripts,
        ({ src, type }) => {
          if (!processedSearchSources.has(src)) {
            processedSearchSources.add(src);

            appendAsyncScriptElement(src, type)
              .then(
                () => {
                  if (isGlobalSearchUsed(src)) {
                    if (currentTarget.tagName === buttonTagName) {
                      document.querySelector(globalSearchButtonSelector)?.click();
                      currentTarget.remove();
                    } else {
                      document.querySelector(globalSearchInputSelector)?.focus();
                    }
                  }
                },
                (error) => {
                  log.error('Failed to load script "%s":', src, error);
                },
              );
          }
        },
      );
    };

    const makeSearchVisibleAboveMobileHeader = () => {
      element.closest(searchFieldSelectorWrapper).classList.add(showSeacrhForMobileHeaderVersion8ClassName);
      element.querySelector('[type="search"]').focus();
    };

    if (searchScripts.length) {
      addEventListener(
        element.querySelector(searchFieldSelector),
        'focus',
        appendSearchScripts,
        { once: true },
      );

      addEventListener(
        document.querySelector(mobileSearchButtonSelector),
        'click',
        appendSearchScripts,
        { once: true },
      );
    } else {
      addEventListener(
        document.querySelector(mobileSearchButtonSelector),
        'click',
        makeSearchVisibleAboveMobileHeader,
      );
    }
  });
});
